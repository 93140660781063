import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store/index';
import {
  ActionTypes,
  TypeAction
} from './tax-document.actions';
import {
  createSelectModelFromStringArray
} from '../../../shared/components/select/models/createSelectModel';
import { IRecruitTaxInfo } from '../../../core/services/tax-documents/interfaces';

export interface ITaxFormData {
  fullName: string;
  businessName: string;
  businessType: string;
  exemptPayeeCode: string | null;
  exemptionFromFATCA: string | null;
  addressLine: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  tin: string;
  ssn: string | null;
  ein: string | null;
  signature: string;
}

export interface ITaxSelectModels {
  businessType: string[];
}

export interface IState {
  selectModels: ITaxSelectModels;
  formData: ITaxFormData;
  taxDocumentData: IRecruitTaxInfo;
}

const initialState: IState = {
  selectModels: {
    businessType: []
  },
  formData: {
    fullName: '',
    businessName: '',
    businessType: '',
    exemptPayeeCode: null,
    exemptionFromFATCA: null,
    addressLine: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    tin: '',
    ssn: null,
    ein: null,
    signature: '',
  },
  taxDocumentData: {
    employeeId: null,
    businessName: '',
    businessType: '',
    tin: '',
    fullName: '',
    zip: '',
    addressLine: '',
    addressLine2: '',
    city: '',
    state: '',
  }
};

export function taxDocumentReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.FORM_CONTROL_CHANGE: {
      const { formData } = state;
      const { fieldId, value } = payload;
      const result = Object.assign({}, formData, { [fieldId]: value });

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.STORE_TAX_TYPES: {
      return Object.assign({}, state, { selectModels: payload });
    }

    case ActionTypes.STORE_RECRUIT_FORM_DATA: {
      const { formData } = state;
      const result = {
        ...formData,
        ...payload
      };

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.STORE_TAX_INFO: {
      return Object.assign({}, state, { taxDocumentData: payload });
    }

    default: {
      return state;
    }
  }
}

export const getTaxFormData = createSelector(
  (root: IRootState): IState => root.taxDocuments,
  ({ formData }: IState): ITaxFormData => formData
);

export const getTaxSelectModels = createSelector(
  (root: IRootState): IState => root.taxDocuments,
  ({ selectModels }: IState): any => {
    return Object.keys(selectModels).reduce((acc, key) => {
      acc[key] = createSelectModelFromStringArray(selectModels[key]);

      return acc;
    }, {});
  }
);

export const  getTaxInfo = createSelector(
  (root: IRootState): IState => root.taxDocuments,
  ({ taxDocumentData }: IState): IRecruitTaxInfo => taxDocumentData
);
